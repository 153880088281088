import xhr from './xhr/';
/**
 * 预警管理相关的 API
 */
class HuaweiService {
	// 查看心率
	getHeartRate(data) {
		return xhr({
			method: 'post',
			url: '/hw/trans/heart',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new HuaweiService();
